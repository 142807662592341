import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';

export const ApplicationRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const idParam = route.params['id'];
	if (idParam) {
		const router = inject(Router);
		console.log('ID param is present');
		router.navigate([`debitcardppplication/otpValidation/${idParam}`]);
	}
	return false;
};
