import { Injectable } from '@angular/core';
import { AddressInfo } from '../models/models';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Injectable({
	providedIn: 'root',
})
export class LocationService implements ILocationService {
	// Earth's mean radius expressed in meters
	private readonly earthRadius: number = 6378137;
	private readonly meterToMileConversionFactor: number = 0.000621371;
	private readonly meterToKilometerConversionFactor: number = 0.001;

	constructor() {}

	private getAddressComponent(address: Address, typeName: string) {
		return address.address_components.find((x) => x.types.some((y) => y === typeName));
	}

	/**
	 * Converts a given value to radian
	 * @param value
	 * @returns
	 */
	private toRadian(value: number): number {
		return (value * Math.PI) / 180;
	}

	/**
	 * Given two addresses, returns the distance between them using the 'Haversine' method
	 * @param sourceAddress : Source address info
	 * @param destinationAddress : DestinationAddressInfo
	 * @returns distance in meters
	 */
	public distanceInMeters(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number {
		const sourceLatitude = Number.parseFloat(sourceAddress.latitude ?? '0');
		const sourceLongitude = Number.parseFloat(sourceAddress.longitude ?? '0');

		const destinationLatitude = Number.parseFloat(destinationAddress.latitude ?? '0');
		const destinationLongitude = Number.parseFloat(destinationAddress.longitude ?? '0');

		const latitudeDiff = this.toRadian(destinationLatitude - sourceLatitude);
		var longDiff = this.toRadian(destinationLongitude - sourceLongitude);

		const evaluatedValue =
			Math.sin(latitudeDiff / 2) * Math.sin(latitudeDiff / 2) +
			Math.cos(this.toRadian(sourceLatitude)) * Math.cos(this.toRadian(destinationLatitude)) * Math.sin(longDiff / 2) * Math.sin(longDiff / 2);
		const finalValue = 2 * Math.atan2(Math.sqrt(evaluatedValue), Math.sqrt(1 - evaluatedValue));
		const distance = this.earthRadius * finalValue;
		return distance;
	}

	/**
	 * Given two addresses, returns the distance between them using the 'Haversine' method
	 * @param sourceAddress : Source address info
	 * @param destinationAddress : DestinationAddressInfo
	 * @returns distance in miles
	 */
	public distanceInMile(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number {
		const distanceInMeters = this.distanceInMeters(sourceAddress, destinationAddress);
		return distanceInMeters * this.meterToMileConversionFactor;
	}

	/**
	 * Given two addresses, returns the distance between them using the 'Haversine' method
	 * @param sourceAddress : Source address info
	 * @param destinationAddress : DestinationAddressInfo
	 * @returns distance in KM
	 */
	public distanceInKiloMeter(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number {
		const distanceInMeters = this.distanceInMeters(sourceAddress, destinationAddress);
		return distanceInMeters * this.meterToKilometerConversionFactor;
	}

	/**
	 * Translate a google Address object into TRB AdressInfo object
	*/
	public parseGoogleAddress(address: Address): AddressInfo {
		const addressInfo: AddressInfo = {
			name: address.name ?? '',
			address: `${this.getAddressComponent(address, 'street_number')?.short_name ?? ''} ${this.getAddressComponent(address, 'route')?.short_name ?? ''}`.trim(),
			city: this.getAddressComponent(address, 'locality')?.short_name ?? this.getAddressComponent(address, 'administrative_area_level_2')?.short_name,
			state: this.getAddressComponent(address, 'administrative_area_level_1')?.short_name,
			zip: this.getAddressComponent(address, 'postal_code')?.short_name,
			country: this.getAddressComponent(address, 'country')?.short_name,
			latitude: address.geometry.location.lat().toString(),
			longitude: address.geometry.location.lng().toString(),
			formatted_address: address.formatted_address,
		};
		return addressInfo;
	}
}

export interface ILocationService {
	distanceInMeters(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number;
	distanceInMile(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number;
	distanceInKiloMeter(sourceAddress: AddressInfo, destinationAddress: AddressInfo): number;
}
