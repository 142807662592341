<ngx-spinner [name]="spinnerName" size="medium" color="#fff" type="square-jelly-box" bdColor="rgba(255,255,255,0.8);" [fullScreen]="false">
	<div id="wrapper">
		<div class="gears">
			<div class="gears-container">
				<div class="gear-rotate"></div>
				<div class="loading-text-sec">
					<span class="loading-text">Loading...</span>
				</div>
			</div>
		</div>
	</div>
</ngx-spinner>
