import { ElementRef, Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class CommonService {
	public errorMessages = {
		firstName: 'First name is required.',
		lastName: 'Last name is required.',
		email: 'Please enter a valid email address.',
		mobileNumber: 'Mobile number is required.',
		invalidMobileNumber: 'Please enter a valid mobile number.',
		countryCode: 'Please select country code.',
	};
	public commonErrorMessages = {
		slotBusy: 'Unfortunately, the slot you are trying to book is no longer available. Please select a different time.',
	};
	constructor() {}

	trimValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value) {
				let trimmedValue = control.value.trim();
				if (trimmedValue === '') {
					return { required: true };
				} else {
					return null;
				}
			}
			return null;
		};
	}

	phoneNumberValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value != null) {
				const phoneNumber = control.value.replace(/\D/g, '');
				const minLength = 10;
				if (phoneNumber.length > 0 && phoneNumber.length < minLength) {
					return { partiallyIncomplete: true };
				}
			}

			return null;
		};
	}

	scrollToFirstInvalidControl(element: ElementRef) {
		setTimeout(() => {
			const firstInvalidControl: HTMLElement = element.nativeElement.querySelector('form .validation-error');
			if (firstInvalidControl) {
				firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
			}
		}, 500);
	}
}
