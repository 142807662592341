import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

@Pipe({
	name: 'translateIn',
	pure: true,
})
export class TranslateInPipe implements PipeTransform {
	constructor(protected translateService: TranslateService) {
		translateService.setDefaultLang('en');
	}

	transform(key: string, langCode: string): any {
		if (key) {
			return this.translateService.getTranslation(langCode).pipe(map((translations) => translations[key.replace(/\s/g, '')]));
		}
	}
}

@NgModule({
	declarations: [
		// import all pipes here
		TranslateInPipe,
	],
	imports: [],
	exports: [
		// import all pipes here
		TranslateInPipe,
	],
})
export class TranslateInPipeModule {}
