<ngx-spinner *ngIf="true" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="false">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<!-- <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px" *ngIf="fileName != ''">
	<p>{{ fileLabel }}</p>
	<button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -20px" (click)="onCancel($event)">
		<mat-icon>close</mat-icon>
	</button>
</div> -->
<div style="height: 92%">
	<div #fileView style="width: 100%; height: 100%"></div>
	<div class="footer">
		<div class="account-opening-details-footer-buttons-section">
			<button class="account-opening-white-btn account-opening-details-footer-button" (click)="onCancel($event)">
				{{ fileCancelBtnLabel }}
			</button>
			<button style="border: none" class="account-opening-blue-btn account-opening-details-footer-button" (click)="onAcknowledgeFile($event)">{{ fileAcknowledgeBtnLabel }}</button>
		</div>
	</div>
</div>
