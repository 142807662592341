<dx-popup [(visible)]="isCarouselPopupOpen" height="600px" width="70%" [hideOnOutsideClick]="true" (onHiding)="closeCarousel()">
	<dx-gallery
		[dataSource]="images"
		height="100%"
		width="100%"
		class="image-section"
		[loop]="false"
		[showIndicator]="showIndicator"
		[showNavButtons]="showNavButtons"
		[slideshowDelay]="slideshowDelay"
		(onItemClick)="openLightbox($event.itemData)"
		><div *dxTemplate="let galleryItem of 'item'">
			<img class="zoomed-image" src="{{ galleryItem }}" />
		</div>
	</dx-gallery>
</dx-popup>
