import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DxGalleryModule, DxPopupModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'app-image-carousel',
	templateUrl: './image-carousel.component.html',
	styleUrls: ['./image-carousel.component.scss'],
	imports: [DxGalleryModule, CommonModule, DxPopupModule],
})
export class ImageCarouselComponent implements OnInit {
	// Input property to accept an array of image URLs
	@Input() images: string[] = [];

	// Optional inputs for gallery configuration
	@Input() height: string | number = '300px';
	@Input() showIndicator: boolean = true;
	@Input() showNavButtons: boolean = true;
	@Input() slideshowDelay: number = 2000;
	@Input() isCarouselPopupOpen = false;

	@Output() closeModal = new EventEmitter<void>();

	// Lightbox control variables

	isLightboxOpen: boolean = false;
	currentImage: string | null = null;
	isZoomed: boolean = false;

	// Current index of the image
	currentIndex: number = 0;

	openLightbox(imageUrl: string) {
		// this.currentImage = imageUrl;
		// this.currentIndex = this.images.indexOf(imageUrl); // Set current index
		// // Set current index
		// this.isLightboxOpen = true;
		// this.isZoomed = false; // Reset zoom state when opening lightbox
	}

	closeLightbox() {
		this.isLightboxOpen = false;
		this.currentImage = null;
		this.isZoomed = false; // Reset zoom state on close
	}

	toggleZoom(event: Event) {
		event.stopPropagation(); // Prevent closing the lightbox
		this.isZoomed = !this.isZoomed; // Toggle zoom state
	}

	nextImage(event: MouseEvent) {
		debugger;
		event.stopPropagation(); // Prevent closing the lightbox
		this.currentIndex = (this.currentIndex + 1) % this.images.length;
		this.currentImage = this.images[this.currentIndex];
	}

	prevImage(event: MouseEvent) {
		debugger;
		event.stopPropagation(); // Prevent closing the lightbox
		this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
		this.currentImage = this.images[this.currentIndex];
	}

	openCarousel() {
		this.isCarouselPopupOpen = true;
	}

	closeCarousel() {
		this.isCarouselPopupOpen = false;
		this.closeModal.emit();
	}
	constructor() {}

	ngOnInit() {
		console.log(this.images);
	}
}
